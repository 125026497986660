import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import HowItWorks from './pages/HowItWorks';
import About from './pages/About';
import Contact from './pages/Contact';
import VehiclesCarousel from './components/VehiclesCarousel';
import VehicleResults from './components/VehicleResults';
import LoginScreen from './pages/LoginScreen';
import MainLayout from './components/MainLayout';
import VehicleLayout from './components/VehicleLayout';
import LoginLayout from './components/LoginLayout';
import Dashboard from './dashboard/Dashboard';
import AllRecords from './dashboard/AllRecords';
import PostAdForm from './dashboard/PostAdForm';
import EditRecord from './dashboard/EditRecord';
import Users from './dashboard/Users';
import VehicleDetails from './components/VehicleDetails';
import ChangePassword from './pages/Change';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/about" element={<About />} />
          <Route path="/vehicles-carousel" element={<VehiclesCarousel />} />
          <Route path="/contact" element={<Contact />} />
        </Route>

        <Route element={<VehicleLayout />}>
          <Route path="/find-vehicle" element={<VehicleResults />} />
          <Route path="/vehicle/:id" element={<VehicleDetails />} />
        </Route>

        <Route element={<LoginLayout />}>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/dashboard/change-password" element={<ChangePassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/users" element={<Users />} />          
          <Route path="/dashboard/allRecords" element={<AllRecords />} />
          <Route path="/dashboard/add-new-listing" element={<PostAdForm />} />
          <Route path="/dashboard/allrecords/editrecord" element={<EditRecord />} />
        </Route>


      </Routes>
    </Router>
  );
}

export default App;
