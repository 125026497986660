import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../css/CarouselVehicleCard.css';

const VehiclesCarousel = () => {
  const [vehicles, setVehicles] = useState([]);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchVehicles = async () => {
      const vehiclesCollection = collection(db, 'vehicles');
      
      const vehiclesQuery = query(
        vehiclesCollection,
        orderBy('postedWhen', 'desc'),
        limit(5)
      );
      
      const vehicleSnapshot = await getDocs(vehiclesQuery);
      const vehicleList = vehicleSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Include document ID
      setVehicles(vehicleList);
    };
  
    fetchVehicles();
  }, []);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
    }).format(price);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // Click handler for navigating to the vehicle details page
  const handleVehicleClick = (id) => {
    navigate(`/vehicle/${id}`);
  };

  return (
    <section>
      <div className="carousel-container">
        <div className="top-vehicle-heading">
          <h1>Latest 5 vehicles added </h1>
        </div>
        <Carousel
          arrows
          autoPlaySpeed={3000}
          centerMode={true}
          containerClass="carousel-container"
          infinite
          responsive={responsive}
          showDots={true}
          swipeable
        >
          {vehicles.map((vehicle, index) => (
            <div
              key={index}
              className="vehicle-card"
              onClick={() => handleVehicleClick(vehicle.id)} // Handle click
            >
              {vehicle.imageUrls && vehicle.imageUrls.length > 0 ? (
                <img src={vehicle.imageUrls[0]} alt={vehicle.title} className="vehicle-image" />
              ) : (
                <p>No Image Available</p>
              )}
              <h3 className="vehicle-price">{`JMD $${formatPrice(vehicle.price)}`}</h3>
              <h5 className="vehicle-title">{vehicle.title}</h5>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default VehiclesCarousel;
