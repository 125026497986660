import React, { useEffect, useState } from "react";
import BannerImage from "../assets/home-banner-image.png";
import Google from "../assets/google.png";
import Apple from "../assets/apple.png";
import Navbar from "../components/Navbar";

const Home = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowBackToTop(true);
        document.querySelector('nav').classList.add('opaque');
      } else {
        setShowBackToTop(false);
        document.querySelector('nav').classList.remove('opaque');
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section id="home">
      <Navbar />
      <div className="home-banner-container">

        <div className="home-text-section">
          <h1 className="primary-heading">
            This app is all you need to find your next dream car.
          </h1>
          <p className="primary-text">
            Save yourself the hassle of running around trying to find a vehicle.
          </p>
          <div className="get-app-btn">
          <a href="https://play.google.com/store/apps/details?id=com.adkemis.adkemisapp" target="_blank" rel="noopener noreferrer">
            <img src={Google} alt="google" /> 
          </a>
          <a href="https://apps.apple.com/us/app/adkemis/id6670189864" target="_blank" rel="noopener noreferrer">  
            <img src={Apple} alt="apple" />
          </a>
          </div>

        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="Adkemis" />
        </div>
      </div>
      {showBackToTop && (
        <button className="back-to-top" onClick={scrollToTop}>

        </button>
      )}
    </section>
  );
};

export default Home;


//https://play.google.com/store/apps/details?id=com.adkemis.adkemisapp

//https://apps.apple.com/us/app/adkemis/id6670189864