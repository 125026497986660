import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './css/editRecord.css'; // Import the CSS file

const EditRecord = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: 0,
    negPrice: 0,
    imageUrls: [],
    // Add other fields as needed
  });
  const [loading, setLoading] = useState(true);
  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
    const fetchRecord = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "vehicles", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setFormData(data);
          setImagePreviews(data.imageUrls || []);
          setLoading(false);
        } else {
          console.log("No such document!");
          navigate('/dashboard/allrecords'); // Redirect if the document doesn't exist
        }
      } catch (err) {
        console.error("Error fetching document: ", err);
        setLoading(false);
      }
    };

    fetchRecord();
  }, [id, navigate]);

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const newImages = [...formData.imageUrls];
      newImages[index] = file;

      const newPreviews = [...imagePreviews];
      newPreviews[index] = URL.createObjectURL(file);

      setFormData((prevState) => ({
        ...prevState,
        imageUrls: newImages
      }));
      setImagePreviews(newPreviews);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted.");

    try {
      const imageUrls = [];

      // Upload images to Firebase Storage and get their URLs
      for (let i = 0; i < formData.imageUrls.length; i++) {
        const file = formData.imageUrls[i];
        if (file instanceof File) {
          console.log(`Uploading image: ${file.name}`);
          const storageRef = ref(storage, `ad-images/${Date.now()}-${file.name}`);
          await uploadBytes(storageRef, file);
          const url = await getDownloadURL(storageRef);
          imageUrls.push(url);
          console.log(`Uploaded image URL: ${url}`);
        } else {
          imageUrls.push(file); // Use existing URL if it's not a File instance
        }
      }

      // Ensure imageUrls array is properly updated
      const updatedFormData = {
        ...formData,
        imageUrls: imageUrls,
      };

      // Update the document in Firestore
      const docRef = doc(db, "vehicles", id);
      await updateDoc(docRef, updatedFormData);

      console.log("Document updated with ID: ", docRef.id);
      alert('Ad updated successfully!');
      navigate('/dashboard/allrecords'); // Redirect to the all records page

    } catch (error) {
      console.error("Error updating document: ", error);
      alert(`Error updating ad: ${error.message}`);
    }
  };

  if (loading) {
    return <p>Loading record...</p>;
  }

  return (
    <div className="edit-record-container">
      <h1>Edit Record</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Title</label>
          <input type="text" name="title" value={formData.title} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Price</label>negPrice
          <input type="number" name="price" value={formData.price} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Negotiable Price</label>
          <input type="number" name="negPrice" value={formData.negPrice} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea name="description" value={formData.description} onChange={handleChange}></textarea>
        </div>
        <div className="form-group">
          <label>Images</label>
          <div className="image-preview-container">
            {imagePreviews.map((preview, index) => (
              <div key={index} className="image-upload-slot">
                <label htmlFor={`file-upload-${index}`} className="custom-file-upload">
                  <img src={preview} alt={`Preview ${index}`} className="image-preview" />
                </label>
                <input type="file" id={`file-upload-${index}`} className="file-input" onChange={(e) => handleImageChange(e, index)} />
              </div>
            ))}
          </div>
        </div>
        <button type="submit">Update Record</button>
      </form>
    </div>
  );
};

export default EditRecord;
