import React, { useState } from 'react';
import { updatePassword } from 'firebase/auth';
import { auth } from '../firebase'; // Ensure the path is correct
import { useNavigate } from 'react-router-dom';
import '../css/LoginScreen.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();


  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      const user = auth.currentUser;
      if (user) {
        await updatePassword(user, newPassword);
        setMessage('Password has been successfully updated.');
        setTimeout(() => {
          navigate('/login');
        }, 4000); // Redirect after 4 seconds
      } else {
        setError('No user is currently signed in.');
      }
    } catch (err) {
      setError('Failed to update password. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handlePasswordChange}>
        <h2>Change Your Password</h2>
        <p>Enter a new password below to update your current password.</p>
        {message && <div className="success-message">{message}</div>}
        {error && <div className="error-message">{error}</div>}
        <div className="password-input-container">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <span
            className="show-password-icon"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <button type="submit">Update Password</button>
        <a href="/login" className="back-to-login">Back to Login</a>
      </form>
    </div>
  );
};

export default ChangePassword;
