import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import '../css/LoginLayout.css';


const LoginLayout = () => {
  return (
    <div className="login-layout-container">       
      <main className="login-content">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default LoginLayout;
