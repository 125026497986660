import React, { useState } from 'react';
import { db, storage } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './css/form.css';

const PostAdForm = () => {
    const categories = [
        'Cars', 'Vans', 'Buses', 'Trucks', 'Motorbikes', 'Car parts',
        'Boats', 'Tractor', 'Trailers', 'Services'
    ];

    const makes = ["Acura", "AlfaRomeo", "AstonMartin", "Audi", "Bentley", "Bmw", "Bugatti", "Buick", "Cadillac", "Chevrolet", "Chrysler",
        "Dodge", "Ferrari", "Fiat", "Ford", "Genesis", "GMC", "Honda", "Hyundai", "Infiniti", "Jaguar", "Jeep", "Kia", "Lamborghini", "LandRover",
        "Lexus", "Maserati", "Mazda", "McLaren", "Mercedes", "Mini", "Mitsubishi", "Nissan", "Pagani", "Porsche", "Ram", "RollsRoyce", "Subaru", "Tesla", "Toyota",
        "Volkswagen", "Volvo"]; // Add more makes as needed

    const models = {
        Acura: ["ILX", "MDX", "NSX", "RDX", "RLX", "TLX"],
        AlfaRomeo: ["4C", "Giulia", "Stelvio"],
        AstonMartin: ["DB11", "DBS", "DBX", "Rapide", "Vantage"],
        Audi: ["A1", "A3", "A4", "A5", "A6", "A7", "A8", "Q2", "Q3", "Q5", "Q7", "Q8", "R8", "RS3", "RS4", "RS5", "RS6", "S1", "S3", "S4", "S5", "S8", "TT", "TTS"],
        Bentley: ["Bentayga", "Continental", "Flying Spur"],
        Bmw: ["1-Series", "2-Series", "3-Series", "4-Series", "5-Series", "6-Series", "7-Series", "8-Series", "i3", "i8", "M1", "M2", "M3", "M4", "M5", "M6", "X1", "X2", "X3", "X4", "X5", "X6", "Z3", "Z4", "Z8"],
        Bugatti: ["Chiron", "Veyron"],
        Buick: ["Enclave", "Encore", "Envision"],
        Cadillac: ["CT4", "CT5", "CT6", "Escalade", "XT4", "XT5", "XT6"],
        Chevrolet: ["Blazer", "Cruze", "Equinox", "Impala", "Malibu", "Sonic", "Spark", "Suburban", "Tahoe", "Traverse"],
        Chrysler: ["300", "Pacifica", "Voyager"],
        Dodge: ["Challenger", "Charger", "Durango", "Journey"],
        Ferrari: ["488", "812", "Portofino", "Roma", "SF90"],
        Fiat: ["124 Spider", "500", "500X"],
        Ford: ["EcoSport", "Edge", "Escape", "Expedition", "Explorer", "F-150", "Fiesta", "Focus", "Fusion", "Granada", "Mustang", "Ranger"],
        Genesis: ["G70", "G80", "G90", "GV80"],
        GMC: ["Acadia", "Canyon", "Sierra", "Terrain", "Yukon"],
        Honda: ["Accord", "ATV", "Civic", "City", "Clarity", "CR-V", "CR-Z", "Fit", "Freed", "HR-V", "Insight", "Inspire", "Integra", "Jazz", "NSX", "Passport", "Pilot", "Ridgeline", "Step Wagon", "Step Wagon Spada", "Stream", "Vezel", "Vezel RS", "ZR-V"],
        Hyundai: ["Accent", "Creta", "Elantra", "Ioniq", "Kona", "Palisade", "Santa Fe", "Sonata", "Tucson", "Venue"],
        Infiniti: ["EX", "FX", "G", "M", "Q30", "Q50", "Q60", "Q70", "QX30", "QX50", "QX60", "QX80"],
        Jaguar: ["E-Pace", "F-Pace", "F-Type", "I-Pace", "XE", "XF", "XJ"],
        Jeep: ["Cherokee", "Compass", "Gladiator", "Grand Cherokee", "Renegade", "Wrangler"],
        Kia: ["Forte", "Optima", "Rio", "Seltos", "Sorento", "Soul", "Sportage", "Stinger", "Telluride"],
        Lamborghini: ["Aventador", "Huracan", "Urus"],
        LandRover: ["Defender", "Discovery", "Discovery Sport", "Range Rover", "Range Rover Evoque", "Range Rover Sport", "Range Rover Velar"],
        Lexus: ["CT", "HS", "ES", "GS", "GX", "IS", "LC", "LS", "LX", "LFA", "NX", "RC", "RX"],
        Maserati: ["Ghibli", "GranTurismo", "Levante", "Quattroporte"],
        Mazda: ["Atenza", "Axela", "Bongo", "Carol", "CX-3", "CX-30", "CX-5", "CX-8", "CX-9", "Demio", "Familia", "Mazda2", "Mazda3", "Mazda6", "MX-5 Miata", "Premacy", "MPV", "Titan", "Tribute"],
        McLaren: ["570S", "600LT", "720S", "Artura", "GT"],
        Mercedes: ["A-Class", "B-Class", "C-Class", "CLA", "CLS", "E-Class", "G-Class", "GLA", "GLC", "GLE", "GLS", "S-Class"],
        Mini: ["Clubman", "Convertible", "Countryman", "Hardtop"],
        Mitsubishi: ["Eclipse Cross", "Lancer", "Mirage", "Outlander"],
        Nissan: ["350z", "AD Wagon", "Altima", "Armada", "B14", "Bluebird Sylphy", "Caravan", "Cube", "Dualis", "Elgrand", "Frontier", "Fuga", "Juke", "Latio", "LaFesta", "Leaf", "March", "Maxima", "Murano", "Navara", "Note", "Pathfinder", "Pickup", "Rogue", "Sentra", "Serena", "Sunny", "Sylphy", "Skyline", "Teana", "Tiida", "Versa", "Wingroad", "X-Trail"],
        Pagani: ["Huayra", "Zonda"],
        Porsche: ["718", "911", "Cayenne", "Macan", "Panamera", "Taycan"],
        Ram: ["1500", "2500", "3500"],
        RollsRoyce: ["Cullinan", "Dawn", "Ghost", "Phantom", "Wraith"],
        Subaru: ["Ascent", "Crosstrek", "Forester", "Impreza", "Legacy", "Outback", "WRX"],
        Tesla: ["Model 3", "Model S", "Model X", "Model Y"],
        Toyota: ["4Runner", "Allex", "Alphard", "Altezza", "AURIS", "Avanza", "Avensis", "Aygo", "Axio", "Aqua", "Allion", "Avalon", "Belta", "Blade", "C-HR", "Caldina", "Cami", "Camry", "Carina", "Celica", "Ceres", "Coaster", "Corolla", "Corolla Altis", "Cross", "Touring", "Corolla XLi", "Corona", "Corsa", "Cressida", "Crown", "curren", "Cynos", "Duet", "Echo", "Esquire", "Estima", "Fielder", "FJ Cruiser", "Fortuner", "FX", "GT86", "Gaia", "Harrier", "Hiace", "Highlander", "Hilux", "Ipsum", "Ist", "Isis", "IQ", "Kluger", "Land Cruiser", "Land Cruiser Prado", "Levin", "Light Truck", "LiteAce", "Lucida", "Mark II", "Mark X", "Mark X ZiO", "Matrix", "MR2", "MRS", "Minibus", "Nadia", "Noah", "Pajero", "Paseo", "Passo", "Pickup", "Picnic", "Prado", "Premio", "Pressage", "Probox", "Previa", "Prius", "Raize", "RAV4", "Reginus", "RunX", "Rush", "SAI", "Scion", "Sienna", "Sienta", "Spacio", "Spade", "Sprinter", "Starlet", "Succeed", "Supra", "Surf", "T100", "Tercel", "Town Ace", "Trueno", "Sequoia", "Tacoma", "Tundra", "Urban Cruiser", "Van", "Vanguard", "Vellfire", "Venza", "Verso", "Vios", "Vista", "Vitz", "Vitz RS G", "Voltz", "Voxy", "Windy", "Wish", "Yaris"],
        Volkswagen: ["Atlas", "Golf", "ID.4", "Jetta", "Passat", "Tiguan"],
        Volvo: ["S60", "S90", "V60", "V90", "XC40", "XC60", "XC90"],
    }; // Add models for each make

    const parishes = ["Clarendon", "Hanover", "Kingston", "Manchester", "Portland", "St. Andrew", "St. Ann", "St. Catherine", "St. Elizabeth", "St. James", "St. Mary", "St. Thomas", "Trelawny", "Westmoreland"];

    const bodyTypes = ["Sedan", "Hatchback", "SUV", "Estate", "Coupe", "Wagon", "Pickup", "Convertible", "Vans", "Wrecker", "Truck", "Motorcycle"];

    const bikeTypes = ["Clothing, Accessories", "Motorbikes", "Quads, ATV, Buggy", "Scooters", "Other", "ATV parts", "Go Karts"];

    const partTypes = ["Alarm systems, security", "Audio, visual, electronics", "Car accessories", "Car parts", "Crashed cars for spares", "Tyres, rims", "Other car parts", "Tools", "Luggage racks, tow bars"];

    const boatTypes = ["Accessories, equipment", "Power boats", "Sailing boats", "Fishing Boats, Dinghy", "Jet Ski's, Other"];

    const serviceTypes = ["Auto repair shops", "Auto insurance", "Auto financing", "Body repairs, paint shops", "Car rent", "Importers, dealers", "Moving", "Transfer, taxi", "Tuning shops", "Other"];

    const colors = ["Black", "Brown", "White", "PearlWhite", "Blue", "Red", "Silver", "Grey", "Bronze", "Yellow", "Beige", "Green", "Orange", "Pink", "Purple", "Burgundy"];

    const gearboxes = ["Manual", "Automatic"];

    const fuelTypes = ["Petrol", "Diesel", "LPG"];

    const noSeats = Array.from({ length: 29 }, (_, i) => ({ label: i + 2, value: i + 2 }));

    const conditions = ["New", "Used"];

    const leftRight = ["Right", "Left"];

    const startYear = 2025;
    const endYear = 1980;
    const years = Array.from({ length: startYear - endYear + 1 }, (_, i) => ({
        label: startYear - i,
        value: startYear - i
    }));

    const engineSizes = ["0.4L", "0.5L", "0.6L", "0.7L", "0.8L", "0.9L", "1.0L", "1.1L", "1.2L", "1.3L", "1.4L", "1.5L", "1.6L", "1.7L", "1.8L", "1.9L", "2.0L", "2.1L", "2.2L", "2.3L", "2.4L", "2.5L",
        "2.6L", "2.8L", "2.9L", "3.0L", "3.1L", "3.2L", "3.3L", "3.4L", "3.5L", "3.6L", "3.7L", "3.8L", "3.9L", "4.0L", "4.1L", "4.2L", "4.3L", "4.4L", "4.5L", "4.6L", "4.7L", "4.8L", "4.9L", "5.0L", "5.1L", "5.2L",
        "5.3L", "5.4L", "5.5L", "5.6L", "5.7L", "5.8L", "5.9L", "6.0L", "6.1L", "6.2L", "6.3L", "6.4L", "6.5L", "6.6L", "6.7L", "6.8L", "6.9L", "7.0L", "7.5L", "8.0L"];

    const driveTrains = ["2WD", "4WD"];

    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        mileage: '',
        condition: '',
        type: '',
        ac: '',
        airbag: '',
        alarm: '',
        bodyType: '',
        brand: '',
        category: '',
        color: '',
        commentsCount: 0,
        description: '',
        drivetrain: '',
        engineSize: '',
        fuelType: '',
        gearbox: '',
        imageUrls: Array(8).fill(null),
        likesCount: 0,
        logo: '',
        model: '',
        neg: '',
        negPrice: 0,
        parish: '',
        postedWhen: '',
        postedbyId: '',
        postedbyName: '',
        power_win_doors: '',
        price: 0,
        rightHandDrive: '',
        saveCount: 0,
        seats: '',
        title: '',
        views: 0,
        year: '',
    });


    const [imagePreviews, setImagePreviews] = useState(Array(8).fill(null));


    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const newImages = [...formData.imageUrls];
            newImages[index] = file;

            const newPreviews = [...imagePreviews];
            newPreviews[index] = URL.createObjectURL(file);

            setFormData((prevState) => ({
                ...prevState,
                imageUrls: newImages
            }));
            setImagePreviews(newPreviews);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleCategoryChange = (e) => {
        setFormData({
            ...formData,
            category: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form submitted");

        // Simple validation
        if (!formData.title || !formData.price || !formData.description || !formData.email || !formData.phone) {
            alert("Please fill in all required fields.");
            return;
        }

        if (formData.neg && formData.negPrice > formData.price) {
            alert("Negotiable price cannot be greater than the original price.");
            return;
        }

        try {
            const imageUrls = [];

            // Upload images to Firebase Storage and get their URLs
            for (let i = 0; i < formData.imageUrls.length; i++) {
                const file = formData.imageUrls[i];
                if (file) {
                    console.log(`Uploading image: ${file.name}`);
                    const storageRef = ref(storage, `ad-images/${Date.now()}-${file.name}`);
                    await uploadBytes(storageRef, file);
                    const url = await getDownloadURL(storageRef);
                    imageUrls.push(url);
                    console.log(`Uploaded image URL: ${url}`);
                }
            }

            // Add the form data along with the image URLs and a timestamp to the "ads" collection
            const docRef = await addDoc(collection(db, "vehicles"), {
                ...formData,
                imageUrls: imageUrls,
                postedWhen: new Date()
            });

            console.log("Document written with ID: ", docRef.id);
            alert('Ad posted successfully!');

            // Reset form data
            setFormData({
                email: '',
                phone: '',
                mileage: '',
                condition: '',
                type: '',
                ac: false,
                airbag: false,
                alarm: false,
                bodyType: '',
                brand: '',
                category: '',
                color: '',
                commentsCount: 0,
                description: '',
                drivetrain: '',
                engineSize: '',
                fuelType: '',
                gearbox: '',
                imageUrls: Array(8).fill(null),
                likesCount: 0,
                logo: '',
                model: '',
                neg: 'no',
                negPrice: 0,
                parish: '',
                postedWhen: '',
                postedbyId: '',
                postedbyName: '',
                power_win_doors: '',
                price: 0,
                rightHandDrive: '',
                saveCount: 0,
                seats: '',
                title: '',
                views: 0,
                year: '',
            });
            setImagePreviews(Array(8).fill(null));

        } catch (error) {
            console.error("Error adding document: ", error);
            alert(`Error posting ad: ${error.message}`);
        }
    };

    const renderCommonFields = () => (
        <>
            <div className="form-group">
                <label>Location</label>
                <select name="parish" value={formData.parish} onChange={handleChange}>
                    <option value="">Select Location</option>
                    {parishes.map((parish) => (
                        <option key={parish} value={parish}>
                            {parish}
                        </option>
                    ))}
                </select>
            </div>
            <div className="form-group">
                <label>Title</label>
                <input type="text" name="title" value={formData.title} onChange={handleChange} />
            </div>
            <div className="form-group">
                <label>Price</label>
                <input type="number" name="price" value={formData.price} onChange={handleChange} />
            </div>



            <div className="check_row checkbox-group">
                <input
                    type="checkbox"
                    name="neg"
                    checked={formData.neg}
                    onChange={handleChange}
                />
                <label>Negotiable</label>
            </div>

            {formData.neg && (
                <div className="form-group">
                    <label>Negotiable Price</label>
                    <input
                        type="number"
                        name="negPrice"
                        value={formData.negPrice}
                        onChange={handleChange}
                    />
                </div>
            )}


            <div className="form-group">
                <label>Images</label>
                <div className="image-preview-container">
                    {formData.imageUrls.map((_, index) => (
                        <div key={index} className="image-upload-slot">
                            <label htmlFor={`file-upload-${index}`} className="custom-file-upload">
                                {imagePreviews[index] ? (
                                    <img src={imagePreviews[index]} alt={`Preview ${index}`} className="image-preview" />
                                ) : (
                                    <span>+</span>
                                )}
                            </label>
                            <input type="file" id={`file-upload-${index}`} className="file-input" onChange={(e) => handleImageChange(e, index)} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="form-group">
                <label>Description</label>
                <textarea name="description" value={formData.description} onChange={handleChange}></textarea>
            </div>
            <div className="form-group">
                <label>Name</label>
                <input type="text" name="postedbyName" value={formData.postedbyName} onChange={handleChange} />
            </div>
            <div className="form-group">
                <label>Email</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} />
            </div>
            <div className="form-group">
                <label>Phone</label>
                <input type="tel" name="phone" value={formData.phone} onChange={handleChange} />
            </div>
        </>
    );

    const renderFieldsByCategory = () => {
        switch (formData.category) {
            case 'Cars':
                return (
                    <>
                        <div className="form-group">
                            <label>Make</label>
                            <select name="brand" value={formData.brand} onChange={handleChange}>
                                <option value="">Select Make</option>
                                {makes.map((brand) => (
                                    <option key={brand} value={brand}>
                                        {brand}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Model</label>
                            <select name="model" value={formData.model} onChange={handleChange}>
                                <option value="">Select Model</option>
                                {formData.brand && models[formData.brand]?.map((model) => (
                                    <option key={model} value={model}>
                                        {model}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Body</label>
                            <select name="bodyType" value={formData.bodyType} onChange={handleChange}>
                                <option value="">Select Body</option>
                                {bodyTypes.map((bodyType) => (
                                    <option key={bodyType} value={bodyType}>
                                        {bodyType}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Gearbox</label>
                            <select name="gearbox" value={formData.gearbox} onChange={handleChange}>
                                <option value="">Select Gearbox</option>
                                {gearboxes.map((gearbox) => (
                                    <option key={gearbox} value={gearbox}>
                                        {gearbox}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Fuel Type</label>
                            <select name="fuelType" value={formData.fuelType} onChange={handleChange}>
                                <option value="">Select Fuel Type</option>
                                {fuelTypes.map((fuelType) => (
                                    <option key={fuelType} value={fuelType}>
                                        {fuelType}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Engine Size</label>
                            <select name="engineSize" value={formData.engineSize} onChange={handleChange}>
                                <option value="">Select Engine size</option>
                                {engineSizes.map((engineSize) => (
                                    <option key={engineSize} value={engineSize}>
                                        {engineSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Year</label>
                            <select name="year" value={formData.year} onChange={handleChange}>
                                <option value="">Year</option>
                                {years.map((year) => (
                                    <option key={year.value} value={year.value}>
                                        {year.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Seats</label>
                            <select name="seats" value={formData.seats} onChange={handleChange}>
                                <option value="">No. of seats</option>
                                {noSeats.map((seats) => (
                                    <option key={seats.value} value={seats.value}>
                                        {seats.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Mileage</label>
                            <input type="number" name="mileage" value={formData.mileage} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Color</label>
                            <select name="color" value={formData.color} onChange={handleChange}>
                                <option value="">Select Color</option>
                                {colors.map((color) => (
                                    <option key={color} value={color}>
                                        {color}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="check_row">
                            <div className="checkbox-group">
                                <input type="checkbox" name="ac" checked={formData.ac} onChange={handleChange} />
                                <label>Air Condition</label>
                            </div>
                            <div className="checkbox-group">
                                <input type="checkbox" name="power_win_doors" checked={formData.power_win_doors} onChange={handleChange} />
                                <label>Powered Windows & Doors</label>
                            </div>
                            <div className="checkbox-group">
                                <input type="checkbox" name="alarm" checked={formData.alarm} onChange={handleChange} />
                                <label>Alarm</label>
                            </div>
                            <div className="checkbox-group">
                                <input type="checkbox" name="airbag" checked={formData.airbag} onChange={handleChange} />
                                <label>Airbag</label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Right Hand Drive</label>
                            <select name="rightHandDrive" value={formData.rightHandDrive} onChange={handleChange}>
                                <option value="">Left/Right Hand drive</option>
                                {leftRight.map((rightHandDrive) => (
                                    <option key={rightHandDrive} value={rightHandDrive}>
                                        {rightHandDrive}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Drivetrain</label>
                            <select name="drivetrain" value={formData.drivetrain} onChange={handleChange}>
                                <option value="">Select Drivetrain</option>
                                {driveTrains.map((drivetrain) => (
                                    <option key={drivetrain} value={drivetrain}>
                                        {drivetrain}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {renderCommonFields()}
                    </>
                );
            case 'Vans':
                return (
                    <>
                        {renderCommonFields()}
                        <div className="form-group">
                            <label>Fuel Type</label>
                            <select name="fuelType" value={formData.fuelType} onChange={handleChange}>
                                <option value="">Select Fuel Type</option>
                                {fuelTypes.map((fuelType) => (
                                    <option key={fuelType} value={fuelType}>
                                        {fuelType}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Engine Size</label>
                            <select name="engineSize" value={formData.engineSize} onChange={handleChange}>
                                <option value="">Select Engine size</option>
                                {engineSizes.map((engineSize) => (
                                    <option key={engineSize} value={engineSize}>
                                        {engineSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Year</label>
                            <select name="year" value={formData.year} onChange={handleChange}>
                                <option value="">Year</option>
                                {years.map((year) => (
                                    <option key={year.value} value={year.value}>
                                        {year.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Seats</label>
                            <select name="seats" value={formData.seats} onChange={handleChange}>
                                <option value="">No. of seats</option>
                                {noSeats.map((seats) => (
                                    <option key={seats.value} value={seats.value}>
                                        {seats.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Mileage</label>
                            <input type="number" name="mileage" value={formData.mileage} onChange={handleChange} />
                        </div>
                    </>
                );
            case 'Buses':
                return (
                    <>
                        {renderCommonFields()}
                    </>
                );
            case 'Trucks':
                return (
                    <>
                        {renderCommonFields()}
                        <div className="form-group">
                            <label>Color</label>
                            <select name="color" value={formData.color} onChange={handleChange}>
                                <option value="">Select Color</option>
                                {colors.map((color) => (
                                    <option key={color} value={color}>
                                        {color}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Year</label>
                            <select name="year" value={formData.year} onChange={handleChange}>
                                <option value="">Year</option>
                                {years.map((year) => (
                                    <option key={year.value} value={year.value}>
                                        {year.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                );
            case 'Motorbikes':
                return (
                    <>
                        <div className="form-group">
                            <label>Type</label>
                            <select name="type" value={formData.type} onChange={handleChange}>
                                <option value="">Select Type</option>
                                {bikeTypes.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {renderCommonFields()}
                    </>
                );
            case 'Car parts':
                return (
                    <>
                        <div className="form-group">
                            <label>Type</label>
                            <select name="type" value={formData.type} onChange={handleChange}>
                                <option value="">Select Type</option>
                                {partTypes.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Condition</label>
                            <select name="condition" value={formData.condition} onChange={handleChange}>
                                <option value="">Condition</option>
                                {conditions.map((condition) => (
                                    <option key={condition} value={condition}>
                                        {condition}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {renderCommonFields()}
                    </>
                );
            case 'Boats':
                return (
                    <>
                        <div className="form-group">
                            <label>Type</label>
                            <select name="type" value={formData.type} onChange={handleChange}>
                                <option value="">Select Type</option>
                                {boatTypes.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Condition</label>
                            <select name="condition" value={formData.condition} onChange={handleChange}>
                                <option value="">Condition</option>
                                {conditions.map((condition) => (
                                    <option key={condition} value={condition}>
                                        {condition}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {renderCommonFields()}
                    </>
                );
            case 'Tractor':
                return (
                    <>
                        {renderCommonFields()}
                    </>
                );
            case 'Trailers':
                return (
                    <>
                        {renderCommonFields()}
                    </>
                );
            case 'Services':
                return (
                    <>
                        <div className="form-group">
                            <label>Type</label>
                            <select name="type" value={formData.type} onChange={handleChange}>
                                <option value="">Select Type</option>
                                {serviceTypes.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {renderCommonFields()}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="container">
            <h1>Post Ad</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Choose Category</label>
                    <select name="category" value={formData.category} onChange={handleCategoryChange}>
                        <option value="">Select</option>
                        {categories.map((category) => (
                            <option key={category} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </div>
                {renderFieldsByCategory()}
                {formData.category && <button type="submit">Submit Ad</button>}
            </form>
        </div>
    );
};

export default PostAdForm;
