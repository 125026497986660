import React from "react";
import '../css/About.css'
import AboutBackgroundImage from "../assets/about-background-image.png";


const About = () => {
  return (
    <section id="about">
      <div className="about-section-container">
        <div className="about-background-image-container">

        </div>
        <div className="about-section-image-container">
          <img src={AboutBackgroundImage} alt="" />
        </div>
        <div className="about-section-text-container">
          <p className="primary-subheading">About Adkemis App</p>
          <h1 className="primary-heading-body">
            Find the car you've always wanted.
          </h1>

          <p className="top-text">
            Welcome to Adkemis App, your premier destination for finding the perfect vehicle in Jamaica!
            Whether you're looking for a car, truck, or motorcycle, we connect buyers and sellers across the island.
            Our platform allows you to search for all types of vehicles, view detailed listings, and easily contact sellers.
          </p>

          <p className="top-text">
            At Adkemis App, we value community engagement. Share your experiences by leaving comments, liking listings,
            and rating vehicles. Join us in creating a vibrant and trusted marketplace for automotive enthusiasts.
          </p>        

        </div>
      </div>
    </section>
  );
};

export default About;