import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png';
import '../css/navbar.css';
import { FaRegUserCircle } from "react-icons/fa";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeHash, setActiveHash] = useState(window.location.hash);
  const navigate = useNavigate();

  useEffect(() => {
    const handleHashChange = () => {
      setActiveHash(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const goToVehicleResults = () => {
    navigate('/find-vehicle');
  };

  const goToLoginScreen = () => {
    navigate('/login');
  };

  return (
    <nav>
      <div className="nav-container">
        <div className="nav-logo-container">
          <a href="#home">
            <img src={Logo} alt="Logo" />
          </a>
        </div>
        <div className={`navbar-links-container ${menuOpen ? 'open' : ''}`}>
          <a href="#home" className={activeHash === '#home' ? 'active-link' : ''}>Home</a>
          <a href="#how" className={activeHash === '#how' ? 'active-link' : ''}>How</a>
          <a href="#about" className={activeHash === '#about' ? 'active-link' : ''}>About</a>
          <a href="#contact" className={activeHash === '#contact' ? 'active-link' : ''}>Contact</a>
          <button className='find-button' onClick={goToVehicleResults}>Find a Car</button>
          <div className="icon-container" onClick={goToLoginScreen}>
            <FaRegUserCircle className="user-icon" />
          </div>
        </div>
        <div className="navbar-menu-container" onClick={toggleMenu}>
          <svg viewBox="0 0 100 80" width="40" height="40">
            <rect width="100" height="10"></rect>
            <rect y="30" width="100" height="10"></rect>
            <rect y="60" width="100" height="10"></rect>
          </svg>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
