import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory
import './CarCard.css'; // Import the CSS file
import moment from 'moment'; // Import moment.js

const CarCard = ({ car }) => {
  const navigate = useNavigate(); 

  const handleClick = () => {
    navigate(`/vehicle/${car.id}`);
  };

  // Check if car.imageUrls is an array and has at least one element
  const imageUrl = Array.isArray(car.imageUrls) && car.imageUrls.length > 0 ? car.imageUrls[0] : '';

  // Format the timestamp
  const formattedDate = car.postedWhen ? moment(car.postedWhen.toDate()).format('MMMM DD, YYYY') : 'Unknown date';

  // Format the price
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JMD',
  }).format(car.price);

  return (
    <div className="carCard" onClick={handleClick}> {/* Attach the handleClick to the card div */}
      {imageUrl ? (
        <img src={imageUrl} alt={car.model} className="carImage" />
      ) : (
        <div className="carImagePlaceholder">No Image</div>
      )}
      <div className="carDetails">
        <h3>{formattedPrice}</h3>
        <p>{`${car.brand} ${car.model}, ${car.engineSize}, ${car.year}`}</p>
        <p>{`Posted: ${formattedDate}, ${car.parish}`}</p>
        <p className='phone'>{`Phone: ${car.phone}`}</p>
        {/* <p className='id'>{`ID: ${car.id}`}</p> */}
      </div>
    </div>
  );
};

export default CarCard;
