import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import '../css/VehicleLayout.css';


const VehicleLayout = () => {
  return (
    <div className="vehicle-layout-container">       
      <main className="vehicle-content">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default VehicleLayout;
