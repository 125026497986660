import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path as necessary
import { FaEdit, FaTrash } from 'react-icons/fa';
//import './css/Users.css';

const Users = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, 'users'));
      const users = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setUsers(users);
    };
    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, 'users', id));
    setUsers(users.filter(user => user.id !== id));
  };

  return (
    <div className="users-container">
      <h2>All Users</h2>
      {users.map(user => (
        <div key={user.id} className="user-item">
          <div className="user-info">
            <h3>{user.name}</h3>
            <p>{user.email}</p>
          </div>
          <div className="user-actions">
            <FaEdit className="icon" onClick={() => {/* Navigate to edit page with user id */}} />
            <FaTrash className="icon" onClick={() => handleDelete(user.id)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Users;
