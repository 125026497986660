// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCXpL-O5twvY1WczynXsSIJtuNHoeAtaKA",
  authDomain: "adkemis-6b72e.firebaseapp.com",
  projectId: "adkemis-6b72e",
  storageBucket: "adkemis-6b72e.appspot.com",
  messagingSenderId: "340307045980",
  appId: "1:340307045980:web:52abf9f696219063f80099"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
