import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import '../css/Contact.css';

const Contact = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState(''); // New name field
  const [message, setMessage] = useState(''); // New message field
  const [status, setStatus] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      setStatus(''); 
      return;
    } else {
      setEmailError('');
    }

    try {
      await addDoc(collection(db, 'contacts'), {
        email,
        name,
        message,
        timestamp: new Date(),
      });
      setStatus('Email sent successfully!');
      setEmail('');
      setName('user from website');
      setMessage('');
      setEmailError(''); 
    } catch (error) {
      console.error('Error adding document: ', error);
      setStatus('Error sending message. Please try again.');
    }
  };

  return (
    <section id="contact">
      <div className="contact-body">
        <div className="contact-page-wrapper">
          <h1 className="primary-heading-body">Have a Question In Mind?</h1>
          {/* <h1 className="primary-subheading">Let Us Hear from You.</h1> */}
          <form className="contact-form-container" onSubmit={handleSubmit}>
            {/* <input
              type="text"
              placeholder="Enter your name here." // New name field
              className="nameTextBox"
              value={name}
              onChange={(e) => setName(e.target.value)}
            /> */}
            <input
              type="text"
              placeholder="Enter your email here."
              className="emailTextBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <textarea
              placeholder="Enter your message here." // New message field
              className="messageTextBox"
              rows={3}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button type="submit" className="secondary-button">
              Send Your Question
            </button>
          </form>
          {emailError && <p className="errorMessage">{emailError}</p>}
          {!emailError && status && <p className="statusMessage">{status}</p>}
        </div>       
      </div>
    </section>
  );
}    

export default Contact