import React from 'react';
import '../css/HowItWorks.css';

const HowItWorks = () => {
    return (
        <section id="how">
            <div className="how-it-works-container">
                <h1 className="secondary-subheading-how1">How it works</h1>
                <p className="secondary-subheading-how2">Find your next dream car today!</p>
                <div className="steps-container">
                    <div className="step">
                        <div className="step-number">1</div>
                        <p className='howText'>Download the app.</p>
                    </div>
                    <div className="step">
                        <div className="step-number">2</div>
                        <p className='howText'>Search for a vehicle.</p>
                    </div>
                    <div className="step">
                        <div className="step-number">3</div>
                        <p className='howText'>Contact the seller.<br/></p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
