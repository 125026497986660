import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './css/allRecords.css'; // Import the CSS file

const AllRecords = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecords = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "vehicles"));
        const recordsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRecords(recordsData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchRecords();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this record?");
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "vehicles", id));
        setRecords(records.filter(record => record.id !== id));
        alert("Record deleted successfully!");
      } catch (err) {
        alert("Error deleting record: " + err.message);
      }
    }
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/allrecords/editrecord?id=${id}`);
    console.log("Edit record with id:", id);
  };

  if (loading) {
    return <p>Loading records...</p>;
  }

  if (error) {
    return <p>Error loading records: {error}</p>;
  }

  return (
    <div className="all-records-container">
      <h2>All Records</h2>
      <div className="records-list">
        {records.map((record) => (
          <div key={record.id} className="record-card">
            {record.imageUrls && record.imageUrls.length > 0 && (
              <img src={record.imageUrls[0]} alt={record.title} className="record-image" />
            )}
            <div className="record-details">
              <h3>{record.title}</h3>
              <p>{record.description}</p>
              <p><strong>Price:</strong> JMD {record.price.toLocaleString()}</p>
            </div>
            <div className="record-actions">
              <button className="edit-button" onClick={() => handleEdit(record.id)}>Edit</button>
              <button className="delete-button" onClick={() => handleDelete(record.id)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllRecords;
