import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import CarCard from './CarCard';
import './Carousel.css'; // Import the CSS file
import * as brands from '../assets/brands'; // Import all brands
import * as icons from '../assets/icons'; // Import all icons

const BrandGrid = () => {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noAdsFound, setNoAdsFound] = useState(false);

  const cards = [
    { image: brands.audi, label: 'Audi' },
    { image: brands.benz, label: 'Mercedes' },
    { image: brands.bmw, label: 'Bmw' },
    { image: brands.jag, label: 'Jaguar' },
    { image: brands.por, label: 'Porsche' },
    { image: brands.land, label: 'Land Rover' },
    { image: brands.hy, label: 'Hyundai' },
    { image: brands.suz, label: 'Suzuki' },
    { image: brands.honda, label: 'Honda' },
    { image: brands.toy, label: 'Toyota' },
    { image: brands.kia, label: 'Kia' },
    { image: brands.lexus, label: 'Lexus' },
    { image: brands.vw, label: 'VW' },
    { image: brands.sub, label: 'Subaru' },
    { image: brands.infin, label: 'Infinity' },
    { image: brands.ford, label: 'Ford' },
    { image: brands.mit, label: 'Mitsubishi' },
    { image: brands.nis, label: 'Nissan' },
  ];

  const typeCards = [
    { image: icons.car, label: 'Cars' },
    { image: icons.bike, label: 'Bikes' },
    { image: icons.van, label: 'Vans' },
    { image: icons.bus, label: 'Buses' },
    { image: icons.boat, label: 'Boats' },
    { image: icons.truck, label: 'Trucks' },
    { image: icons.trailer, label: 'Trailers' },
    { image: icons.tractor, label: 'Tractors' },
    { image: icons.services, label: 'Services' }
  ];

  const fetchBrandData = useCallback(async (brand) => {
    setLoading(true);
    setNoAdsFound(false);
    setSelectedType(null); // Reset selected type when searching by brand
    console.debug(`Fetching data for brand: ${brand}`);

    const carsRef = collection(db, 'vehicles');
    const carsQuery = query(carsRef, where('brand', '==', brand));

    const querySnapshot = await getDocs(carsQuery);
    const carData = querySnapshot.docs.map(doc => ({
      id: doc.id, // Include the document ID
      ...doc.data(), // Spread the rest of the document data
    }));

    console.debug(`Fetched data:`, carData);

    if (carData.length === 0) {
      setNoAdsFound(true);
      console.debug('No ads found');
    } else {
      setCars(carData);
    }

    setLoading(false);
  }, []);

  const fetchTypeData = useCallback(async (type) => {
    setLoading(true);
    setNoAdsFound(false);
    setSelectedBrand(null); // Reset selected brand when searching by type
    console.log(`Fetching data for type: ${type}`);
  
    const typesRef = collection(db, 'vehicles');
    const typeQuery = query(typesRef, where('category', '==', type));
  
    const querySnapshot = await getDocs(typeQuery);
    const typeData = querySnapshot.docs.map(doc => ({
      id: doc.id, // Include the document ID
      ...doc.data(), // Spread the rest of the document data
    }));
  
    console.log(`Fetched data:`, typeData);
  
    if (typeData.length === 0) {
      setNoAdsFound(true);
      console.log('No ads found');
    } else {
      setCars(typeData);
    }
  
    setLoading(false);
  }, []);
  

  useEffect(() => {
    if (selectedBrand) {
      fetchBrandData(selectedBrand);
    }
  }, [selectedBrand, fetchBrandData]);

  useEffect(() => {
    if (selectedType) {
      fetchTypeData(selectedType);
    }
  }, [selectedType, fetchTypeData]);

  return (
    <div>
      <h2>Search by Brand or Category </h2>
      <div className="brandGrid">
        {cards.map((card, index) => (
          <div key={index} className="brandCard" onClick={() => { setSelectedBrand(card.label); setNoAdsFound(false); }}>
            <img src={card.image} alt={card.label} className="brandImage" />
            <p>{card.label}</p>
          </div>
        ))}
      </div>

      <hr className='horizontalLine'/>
      
      <div className="typeGrid">
        {typeCards.map((typeCard, index) => (
          <div key={index} className="typeCard" onClick={() => { setSelectedType(typeCard.label); setNoAdsFound(false); }}>
            <img src={typeCard.image} alt={typeCard.label} className="typeImage" />
            <p>{typeCard.label}</p>
          </div>
        ))}
      </div>

      {(selectedBrand || selectedType) && (
        <div>
          {noAdsFound ? (
            <p className="noAdsFound">No ads found for {selectedBrand || selectedType}.</p>
          ) : (
            <>
              {cars.map((car, index) => (
                <CarCard key={index} car={car} />
              ))}
            </>
          )}
          {loading && <p>Loading...</p>}
        </div>
      )}
    </div>
  );
};

export default BrandGrid;
