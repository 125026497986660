import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';// Adjust the path as necessary
import Footer from './Footer'; // Adjust the path as necessary
import HowItWorks from '../pages/HowItWorks';
import About from '../pages/About';
import VehiclesCarousel from './VehiclesCarousel';
import Contact from '../pages/Contact';

const MainLayout = () => {
    
    return (
        <div className="layout-container">
            <Navbar />
            <main className="content">
                <Outlet />
            </main>
            <HowItWorks />
            <About />
            <VehiclesCarousel />
            <Contact />
            <Footer />
        </div>
    );
};

export default MainLayout;
